const routes = [
  {
    path: "permissoes",
    component: () => import("@/views/admin/permissoes/Permissoes.vue"),
    meta: {
      menu: "dashboard",
      title: "Permissões",
    },
  },
  {
    path: "permissao/:permissaoId",

    component: () => import("@/views/admin/permissoes/Permissao.vue"),
    meta: {
      menu: "dashboard",
      title: "Permissão",
    },
  },
  {
    path: "permissoes/add",
    component: () => import("@/views/admin/permissoes/NovaPermissao.vue"),
    meta: {
      menu: "dashboard",
      title: "Novo",
    },
  },
];

export default routes;
