const routes = [
  {
    path: "home",
    component: () => import("@/views/colaborador/home/Home.vue"),
    meta: {
      menu: "dashboard",
      title: "Home",
    },
  },
  {
    path: "/colaborador/calendario-atividades",
    component: () =>
      import(
        "@/views/colaborador/calendario_atividades/CalendarioAtividades.vue"
      ),
    meta: {
      menu: "dashboard",
      title: "Colaborador-Atividades",

    },
  },
];

export default routes;
