const routes = [
  {
    path: "dados/empresa",
    component: () => import("@/views/gestor/empresa/Empresa.vue"),
    meta: {
      menu: "dashboard",
      title: "Empresa",
      componente: "vueGestorDadosEmpresa",
      type: "menu",
    },
  },
  {
    path: "dados/empresa/adicionar",
    component: () => import("@/views/gestor/empresa/AdicionarEmpresa.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Empresa",
    },
  },
];

export default routes;
