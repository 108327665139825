const routes = [
  /*CALENDARIO MIDIAS SOCIAIS*/
  {
    path: "/colaborador/midias-sociais/calendario-midias-sociais",
    component: () =>
      import(
        "@/views/colaborador/midias_sociais/calendario_midias_sociais/CalendariosMidiaSociais.vue"
      ),
    meta: {
      menu: "dashboard",
      title: "Calendário-Mídias-Sociais",

    },
  },
  {
    path: "/colaborador/midias-sociais/calendario-midias-sociais/:calendarioId",
    component: () =>
      import(
        "@/views/colaborador/midias_sociais/calendario_midias_sociais/calendario_postagens/CalendarioMidiaSociais.vue"
      ),
    meta: {
      menu: "dashboard",
      title: "Postagem-Calendario",
    },
  },
  /*------------------------------------------------------------------------------------------------*/
];
export default routes;
