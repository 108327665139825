import api from "../axios_service.js";

export async function fetchNotificacoes(filtros) {
  const response = await api.get(`/notificacoes${filtros ? filtros : ""}`);
  return response.data.result;
}
export async function fetchNotificacoesColaborador(filtros) {
  const response = await api.get(
    `/notificacoes-colaborador${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchNotificacoesCliente(filtros) {
  const response = await api.get(
    `/notificacoes-cliente${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchNotificacoesLidasGestor(filtros) {
  const response = await api.get(
    `/notificacoes-gestor-lidas${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchNotificacoesLidasColaborador(filtros) {
  const response = await api.get(
    `/notificacoes-colaborador-lidas${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchNotificacoesLidasCliente(filtros) {
  const response = await api.get(
    `/notificacoes-cliente-lidas${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export function postNotificao(notificao) {
  let body = {};
  for (let key in notificao) {
    body[key] = notificao[key];
  }
  return api.post("notificacoes/add", body);
}
