const routes = [
    {
      path: "solicitacao-servico",
      component: () => import("@/views/gestor/solitacoes_servicos/SolicitacoesServicosTabs.vue"),
      meta: {
        menu: "dashboard",
        title: "Solicitação-Serviço",
      },
    },
  ];
  
  export default routes;
  