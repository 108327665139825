import api from "../axios_service.js";

export async function getComentarios(filtros) {
  const response = await api.get(
    `/gestor/comentarios${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export function putMsgLida(id) {
  return api.put(`/gestor/comentarios/${id}`);
}

export async function getQtdNaoLidas(filtros) {
  const response = await api.get(`/comentarios-count${filtros ? filtros : ""}`);
  return response.data.result;
}
