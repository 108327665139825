import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/lib/locale/pt";
import en from "vuetify/lib/locale/en";
import es from "vuetify/lib/locale/es";

Vue.use(Vuetify);

const locale = window.localStorage.agency__locale
  ? JSON.parse(window.localStorage.agency__locale)
  : null;

export default new Vuetify({
  theme: {
    dark: false,
    themes: {},
  },
  lang: {
    locales: { pt, en, es },
    // current: Vue.ls.get("locale", navigator.language.substring(0, 2)),
    current: locale ? locale.value : "pt",
  },
});
