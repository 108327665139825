const routes = [
  {
    path: "/gestor/comentarios",
    component: () => import("@/views/gestor/comentarios/ComentariosTabs.vue"),
    meta: {
      title: "Comentarios",
    },
  },
];

export default routes;
