import api from "../axios_service.js";

export async function login(usuario) {
  const response = await api.post("/login", {
    email: usuario.email,
    password: usuario.password,
  });
  return response.data;
}

export async function profile() {
  const response = await api.get("/profile");
  return response.data.result;
}

