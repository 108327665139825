import {
  fetchNotificacoes,
  fetchNotificacoesColaborador,
  fetchNotificacoesCliente,
} from "@/api/geral/notificacoes.js";

const initialState = () => ({
  count_notificacao: null,
});

const state = initialState();

const getters = {};

const actions = {
  count({ rootState, commit }) {
    if (
      rootState.Usuario.perfil == "ADMIN" ||
      rootState.Usuario.perfil == "GESTOR"
    ) {
      return fetchNotificacoes(`?user_id=${rootState.Usuario.usuario.id}`).then(
        (response) => {
          commit("UPDATE_COUNT", response.total);
        }
      );
    } else if (rootState.Usuario.perfil == "COLABORADOR") {
      return fetchNotificacoesColaborador(
        `?user_id=${rootState.Usuario.usuario.id}`
      ).then((response) => {
        commit("UPDATE_COUNT", response.total);
      });
    } else if (rootState.Usuario.perfil == "CLIENTE") {
      return fetchNotificacoesCliente(
        `?user_id=${rootState.Usuario.usuario.id}`
      ).then((response) => {
        commit("UPDATE_COUNT", response.total);
      });
    }
  },
};

const mutations = {
  UPDATE_COUNT(state, payload) {
    state.count_notificacao = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
