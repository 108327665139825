import { login, profile } from "@/api/geral/auth.js";
import { putUsuario } from "@/api/admin/users.js";
import Vue from "vue";

const initialState = () => ({
  usuario: {
    name: null,
  },
  avatar: "",
  user_name: "",
  perfis: null,
});

const state = initialState();

const getters = {
  perfisToArray: (state) => {
    return state.perfis ? state.perfis.map((perfil) => perfil.descricao) : null;
  },
  isAdmin: (state) => {
    return state.perfis ? state.perfis.map((perfil) => perfil.descricao).includes("ADMIN") : false;
  },
  isGestor: (state) => {
    return state.perfis ? state.perfis.map((perfil) => perfil.descricao).includes("GESTOR") : false;
  },
  isCliente: (state) => {
    return state.perfis ? state.perfis.map((perfil) => perfil.descricao).includes("CLIENTE") : false;
  },
  isColaborador: (state) => {
    return state.perfis ? state.perfis.map((perfil) => perfil.descricao).includes("COLABORADOR") : false;
  }
};

const actions = {
  async getUsuario({ state, dispatch, commit }) {
    if (!state.usuario.name) {
      try {
        const response = await profile();
        commit("UPDATE_USUARIO", response);
        commit("UPDATE_AVATAR", response.avatar);
        commit("UPDATE_USERNAME", response.name);
        commit("UPDATE_PERFIS", response.perfis);
      } catch (error) {
        dispatch("deslogarUsuario");
        throw error;
      }
    }
  },
  async logarUsuario(context, payload) {
    try {
      const response = await login({
        email: payload.email,
        password: payload.senha,
      });
      Vue.ls.set("token", `Bearer ${response.token}`);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  deslogarUsuario(context) {
    Vue.ls.remove("token");
    Vue.ls.remove("current_route");
    context.commit("RESET");
  },
  updateUsuario({ dispatch }, { id, usuario }) {
    return putUsuario(id, usuario).then((response) => {
      if (response.status === 201) {
        dispatch("getUsuario");
      }
    });
  },
  reset({ commit }) {
    commit("RESET");
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_USUARIO(state, payload) {
    state.usuario = payload;
  },
  UPDATE_PERFIS(state, payload) {
    state.perfis = payload;
  },
  UPDATE_AVATAR(state, payload) {
    state.avatar = payload;
  },
  UPDATE_USERNAME(state, payload) {
    state.user_name = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
