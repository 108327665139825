import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { i18n } from "./plugins/i18n";
import * as filters from "./filters";
import VueProgressBar from "vue-progressbar";
import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { VueMaskDirective } from "v-mask";
import Storage from "vue-ls";
import VueCurrencyInput from "vue-currency-input";
import Clipboard from "v-clipboard";

const progress_options = {
  color: "#75e289",
  failedColor: "#d64242",
  thickness: "4px",
};

const toastOptions = {
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.SUCCESS]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-check-circle",
      },
    },
    [TYPE.WARNING]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-alert-circle",
      },
    },
    [TYPE.INFO]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-alert-circle",
      },
    },
    [TYPE.ERROR]: {
      timeout: 4000,
      icon: {
        iconClass: "v-icon mdi mdi-alert",
      },
    },
  },
};

const storage_options = {
  namespace: "agency__", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local", // storage name session, local, memory
};

const currency_options = {
  /* see config reference */
  globalOptions: {
    autoDecimalMode: false,
    currency: null,
    distractionFree: false,
  },
};

Vue.use(VueCurrencyInput, currency_options);
Vue.use(Storage, storage_options);
Vue.use(Toast, toastOptions);
Vue.use(VueProgressBar, progress_options);
Vue.use(Clipboard);

Vue.directive("mask", VueMaskDirective);

Vue.config.productionTip = false;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
