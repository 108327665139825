import ComponentsApi from "@/api/geral/validate_components.js";
import router from "../../router/index.js";
// import Vue from "vue";

const initialState = () => ({
  componentes: null,
});

const state = initialState();

// getters
const getters = {
  getAccess: (state, getters, rootState, rootGetters) => (componente, type) => {
    let result = false;
    let check = 0;
    const isAdmin = rootGetters["Usuario/isAdmin"];

    if (isAdmin) {
      result = true;
    } else {
      let comp = state.componentes?.find(
        (comp) => comp.componente === componente
      );

      if (comp) {
        for (let key in rootState.bitWiseTable) {
          if (type === key) {
            check = rootState.bitWiseTable[key];
          }
        }
        if (comp.status) {
          result = check & comp.nivel_acesso ? true : false;
        }
      }
    }


    return result;
  },
};

// actions
const actions = {
  async getComponentes(context) {
    const id = context.rootState.Usuario.usuario.id;
    const isGestor = context.rootGetters["Usuario/isGestor"];

    if (id && (!context.state.componentes || !context.state.componentes.length) && isGestor) {
      try {
        const response = await ComponentsApi.getComponentes(id);
        context.commit("UPDATE_COMPONENTES", response);
      } catch (error) {
        context.dispatch("Usuario/deslogarUsuario", null, { root: true }).then(() => {
          if (router.currentRoute.path != '/login') {
            router.push("/login");
          }
          // Vue.$toast.error("Não foi possível carregar os componentes, entre em contato com o suporte!");
        });
        throw error;
      }
    }
  },

  async getAccess(context, { componente, type }) {

    let result = false;
    let check = 0;
    const isAdmin = context.rootGetters["Usuario/isAdmin"];

    if (isAdmin) {
      result = true;
    } else {
      let comp = state.componentes?.find(
        (comp) => comp.componente === componente
      );

      if (comp) {
        for (let key in context.rootState.bitWiseTable) {
          if (type === key) {
            check = context.rootState.bitWiseTable[key];
          }
        }
        if (comp.status) {
          result = check & comp.nivel_acesso ? true : false;
        }
      }
    }


    return result;
  },

  reset({ commit }) {
    commit("RESET");
  },
};

// mutations
const mutations = {
  UPDATE_COMPONENTES(state, payload) {
    state.componentes = payload;
  },
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
