const routes = [
  {
    path: "dados",
    component: () => import("@/views/colaborador/dados/Dados.vue"),
    meta: {
      menu: "dashboard",
      title: "Dados",
    },
  },
];

export default routes;
