const routes = [
  {
    path: "dados/equipe",
    component: () => import("@/views/gestor/equipe/Equipe.vue"),
    meta: {
      menu: "dashboard",
      title: "Equipe",  
      componente: "vueGestorDadosEquipe",
      type: "menu",
    },
  },
  {
    path: "dados/equipe/adicionar",
    component: () => import("@/views/gestor/equipe/AddEquipeIndividuo.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar a Equipe",
    },
  },
  {
    path: "dados/equipe/:equipeId",
    component: () => import("@/views/gestor/equipe/EquipeIndividuo.vue"),
    meta: {
      menu: "dashboard",
      title: "Equipe",
    },
  },
];

export default routes;
