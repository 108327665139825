import {
  fetchPreferences,
  putPreferences,
  postPreferences,
} from "@/api/geral/users_preferences.js";
import Vuetify from "../../plugins/vuetify.js";

const initialState = () => ({
  preferencias: null,
  theme: null,
  themeOriginal: {
    light: {
      primary: "#232D58",
      accent: "#EBEEF9",
      secondary: "#3473B8",
      success: "#a5d64c",
      info: "#3473B8",
      warning: "#ff8e00",
      error: "#ff5252",
      prioridade_baixa: "#EBEEF9",
      prioridade_normal: "#50B2E6",
      prioridade_urgente: "#ff8e00",
      prioridade_emergencia: "#BD1E33",
    },
    dark: {
      primary: "#424242",
      accent: "#888888",
      secondary: "#232D58",
      success: "#223981",
      info: "#4D5983",
      warning: "#50B2E6",
      error: "#BD1E33",
    },
  },
  prioridade: {},
  add_preferencias: false,
});

const state = initialState();

const getters = {};

const actions = {
  setTheme({ state }) {
    const dark = state.theme ? state.theme.dark : state.themeOriginal.dark;
    const light = state.theme ? state.theme.light : state.themeOriginal.light;

    Object.keys(dark).forEach((i) => {
      Vuetify.framework.theme.themes.dark[i] = dark[i];
    });
    Object.keys(light).forEach((i) => {
      Vuetify.framework.theme.themes.light[i] = light[i];
    });
  },

  async getPreferencias(context) {
    const user_id = context.rootState.Usuario.usuario.id;

    if (user_id && !context.state.preferencias && !context.state.add_preferencias) {
      const response = await fetchPreferences(user_id);
      if (response) {
        context.commit("UPDATE_PREFERENCIAS", response);
        if (response.theme) {
          context.commit("UPDATE_THEME", JSON.parse(response.theme));
          context.dispatch("setTheme");
        }
        context.commit("UPDATE_ADD_PREFERENCIAS", false);
      } else {
        context.commit("UPDATE_ADD_PREFERENCIAS", true);
      }
    }
  },

  updatePreferencias({ dispatch }, { id, preferencias }) {
    return putPreferences(id, preferencias).then((response) => {
      if (response.status === 201) {
        dispatch("getPreferencias");
      }
    });
  },
  addPreferencias({ dispatch }, preferencias) {
    return postPreferences(preferencias).then((response) => {
      if (response.status === 201) {
        dispatch("getPreferencias");
      }
    });
  },
  resetTheme({ state, dispatch }) {
    let preferencias = {};
    preferencias.user_id = state.preferencias.user_id;
    preferencias.theme = JSON.stringify(state.themeOriginal);

    dispatch("updatePreferencias", {
      id: state.preferencias.id,
      preferencias,
    });
  },
  reset({ commit }) {
    commit("RESET");
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_PREFERENCIAS(state, payload) {
    state.preferencias = payload;
  },
  UPDATE_ADD_PREFERENCIAS(state, payload) {
    state.add_preferencias = payload;
  },
  UPDATE_THEME(state, payload) {
    state.theme = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
