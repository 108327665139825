const routes = [
  {
    path: "clientes",
    component: () => import("@/views/gestor/clientes/Clientes.vue"),
    meta: {
      menu: "dashboard",
      title: "Clientes",
      componente: "vueGestorClientes",
      type: "menu",
    },
  },
  {
    path: "clientes/adicionar",
    component: () => import("@/views/gestor/clientes/NovoCliente.vue"),
    meta: {
      menu: "dashboard",
      title: "NovoCliente",
    },
  },
  {
    path: "clientes/:clienteId",
    component: () => import("@/views/gestor/clientes/cliente/Cliente.vue"),
    meta: {
      menu: "dashboard",
      title: "Cliente",
    },
  },
];

export default routes;
