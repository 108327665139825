import api from "../axios_service.js";

export async function fetchPreferences(user_id) {
  const response = await api.get(`/user-preference/${user_id}`);
  return response.data.result;
}

export function putPreferences(id, theme) {
  let body = {};
  for (let key in theme) {
    body[key] = theme[key];
  }
  return api.put(`/user-preference/${id}`, body);
}

export function postPreferences(theme) {
  let body = {};
  for (let key in theme) {
    body[key] = theme[key];
  }
  return api.post("user-preference/add", body);
}
