const routes = [
  {
    path: "audits",
    component: () => import("@/views/admin/audits/ListaAudits.vue"),
    meta: {
      menu: "dashboard",
      title: "Auditoria",
    },
  },
];

export default routes;
