import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export function dateFormat(date, formato) {
  if (date) {
    return format(parseISO(date), formato, { locale: ptBR });
  } else {
    return "";
  }
}

export function status(valor) {
  let result = "";
  switch (valor) {
    case 0:
      result = "Inativo";
      break;
    case 1:
      result = "Ativo";
      break;
  }
  return result;
}

export function statusCalendario(valor) {
  let result = "";
  switch (valor) {
    case -1:
      result = "Cancelado";
      break;
    case 1:
      result = "Ativo";
      break;
    case 2:
      result = "Aguardando Gestor";
      break;
    case 3:
      result = "Aguardando Cliente";
      break;
    case 4:
      result = "Concluido";
      break;
  }
  return result;
}

export function statusAtividade(valor, data) {
  let result = "";
  let hoje = format(new Date(), "yyyy-MM-dd");
  switch (valor) {
    case -1:
      result = "cancelado";
      break;
    case 0:
      result = hoje > data ? "atrasado" : "ativo";
      break;
    case 1:
      result = "andamento";
      break;
    case 2:
      result = "espera";
      break;
    case 3:
      result = "aguardando-gestor";
      break;
    case 4:
      result = "refazer";
      break;
    case 5:
      result = "aguardando-cliente";
      break;
    case 6:
      result = "refazer";
      break;
    case 7:
      result = "finalizado";
      break;
  }
  return result;
}

export function excerpt(text, number) {
  if (!text) return;
  if (text.length > number) {
    return text.substr(0, number) + "...";
  } else {
    return text;
  }
}

export function number(valor) {
  valor = Number(valor);
  if (!isNaN(valor)) {
    return new Intl.NumberFormat({
      maximumFractionDigits: 2,
    }).format(valor);
  } else {
    return "";
  }
}

export function dolar(valor) {
  valor = Number(valor);
  if (!isNaN(valor)) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      currency: "USD",
    }).format(valor);
  } else {
    return "";
  }
}

export function real(valor) {
  valor = Number(valor);
  if (!isNaN(valor)) {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(valor);
  } else {
    return "";
  }
}

export function guarani(valor) {
  valor = Number(valor);
  if (!isNaN(valor)) {
    return new Intl.NumberFormat("es-PY", {
      style: "currency",
      currency: "PYG",
    }).format(valor);
  } else {
    return "";
  }
}

export function _guarani(valor) {
  valor = Number(valor);
  if (!isNaN(valor)) {
    return new Intl.NumberFormat("es-PY", {
      maximumFractionDigits: 0,
    }).format(valor);
  } else {
    return "";
  }
}
export function _real(valor) {
  valor = Number(valor);
  if (!isNaN(valor)) {
    return new Intl.NumberFormat("pt-BR", {
      maximumFractionDigits: 2,
    }).format(valor);
  } else {
    return "";
  }
}
export function _dolar(valor) {
  valor = Number(valor);
  if (!isNaN(valor)) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    }).format(valor);
  } else {
    return "";
  }
}
