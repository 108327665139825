const routes = [
 
  {
    path: "dashboard",
    component: () => import("@/views/gestor/dashboard/Home.vue"),
    meta: {
      menu: "dashboard",
      title: "Dashboard",
      componente: "vueGestorDashboard",
      type: "menu",
    },
  },
];

export default routes;
