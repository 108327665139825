import { getQtdNaoLidas } from "@/api/gestor/comentarios.js";

const initialState = () => ({
  qtde_msgs_comentarios: null,
});

const state = initialState();

const getters = {};

const actions = {
  countComentarios({ commit }) {
    return getQtdNaoLidas().then((response) => {
      let qtde = null;
      qtde = response;

      commit("UPDATE_COUNT_COMENTARIOS", qtde);
    });
  },
};

const mutations = {
  UPDATE_COUNT_COMENTARIOS(state, payload) {
    state.qtde_msgs_comentarios = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
