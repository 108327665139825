const routes = [
  {
    path: "home",
    component: () => import("@/views/admin/home/Home.vue"),
    meta: {
      menu: "dashboard",
      title: "Home",
    },
  },
];

export default routes;
