const routes = [
  {
    path: "/colaborador/notificacoes",
    component: () => import("@/components/notificacao/Notificacao.vue"),
    meta: {
      title: "Notificação",
    },
  },
];

export default routes;
