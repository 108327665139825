const routes = [
  {
    path: "usuarios",
    component: () => import("@/views/admin/usuarios/Usuarios.vue"),
    meta: {
      menu: "dashboard",
      title: "Usuarios",
    },
  },
];

export default routes;
