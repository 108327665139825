const routes = [
  {
    path: "/cliente/relatorios/atividade",
    component: () =>
      import("@/views/cliente/relatorios/RelatoriosAtividades.vue"),
    meta: {
      menu: "dashboard",
      title: "Relatorio-Atividade",
    },
  },
  /*------------------------------------------------------------------------------------------------*/
];
export default routes;
