const routes = [
  {
    path: "perfis",
    component: () => import("@/views/admin/perfis/Perfis.vue"),
    meta: {
      menu: "dashboard",
      title: "Perfis",
    },
  },
];

export default routes;
