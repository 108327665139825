const routes = [
  {
    path: "home",
    component: () => import("@/views/cliente/home/Home.vue"),
    alias: "/cliente",
    meta: {
      menu: "dashboard",
      title: "Home",   
    },
  },
];

export default routes;
