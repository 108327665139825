import { start, stop, check, historico } from "@/api/colaborador/stopwatch.js";

const initialState = () => ({
  elapsedTime: 0,
  timer: undefined,
  running: false,
  historico: null,
  atividade: null,
  historico_atividade: null,
});

const state = initialState();

const getters = {
  formattedElapsedTime: (state) => {
    const date = new Date(null);
    date.setSeconds(state.elapsedTime / 1000);
    const utc = date.toISOString();
    return utc.substr(11, 8);
  },
};

const actions = {
  startTimer({ state }) {
    state.timer = setInterval(() => {
      state.elapsedTime += 1000;
    }, 1000);
  },
  stopTimer({ state }) {
    clearInterval(state.timer);
  },

  check({ state, rootState, commit, dispatch }) {
    check(rootState.Usuario.usuario.colaborador_id).then((response) => {
      if (response) {
        if (response.id_atividade == state.atividade.id) {
          commit("UPDATE_HISTORICO", response);
          commit("UPDATE_ELAPSED", response.time_running);
          commit("UPDATE_RUNNING", true);
          dispatch("startTimer");
        } else {
          commit("UPDATE_ELAPSED", 0);
          commit("UPDATE_RUNNING", false);
          dispatch("getHistorico");
        }
      } else {
        dispatch("getHistorico");
      }
    });
  },

  start({ state, rootState, commit, dispatch }) {
    let body = {};
    body.id_atividade = state.atividade.id;
    body.id_colaborador = rootState.Usuario.usuario.colaborador_id;

    start(body).then((response) => {
      commit("UPDATE_HISTORICO", response.data.result);
      commit("UPDATE_ELAPSED", 0);
      dispatch("startTimer");
      commit("UPDATE_RUNNING", true);
    });
  },

  stop({ state, commit, dispatch }) {
    let body = {};
    body.historico_id = state.historico.id;

    stop(body).then(() => {
      commit("UPDATE_RUNNING", false);
      dispatch("stopTimer");
      dispatch("getHistorico");
      commit("UPDATE_HISTORICO", null);
    });
  },

  setAtividade({ commit }, atividade) {
    commit("UPDATE_ATIVIDADE", atividade);
  },

  getHistorico({ state, commit }) {
    historico(state.atividade.id).then((response) => {
      if (response) {
        commit("UPDATE_HISTORICO_ATIVIDADE", response);
        commit("UPDATE_ELAPSED", response.total_segundo * 1000);
      } else {
        commit("UPDATE_HISTORICO_ATIVIDADE", null);
        commit("UPDATE_ELAPSED", 0);
      }
    });
  },

  reset({ commit, dispatch }) {
    dispatch("stopTimer");
    commit("RESET");
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_ATIVIDADE(state, payload) {
    state.atividade = payload;
  },
  UPDATE_HISTORICO_ATIVIDADE(state, payload) {
    state.historico_atividade = payload;
  },
  UPDATE_HISTORICO(state, payload) {
    state.historico = payload;
  },
  UPDATE_ELAPSED(state, payload) {
    state.elapsedTime = payload;
  },
  UPDATE_TIMER(state, payload) {
    state.timer = payload;
  },
  UPDATE_RUNNING(state, payload) {
    state.running = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
