const routes = [
  {
    path: "dados/cargos",
    component: () => import("@/views/gestor/cargos/Cargos.vue"),
    meta: {
      menu: "dashboard",
      title: "Cargos",
      componente: "vueGestorDadosCargos",
      type: "menu",
    },
  },
];

export default routes;
