<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
    <transition mode="out-in">
      <router-view v-if="!loading" />
      <v-app v-else>
        <v-main>
          <v-container class="fill-height align-center justify-center">
            <v-progress-circular
              :width="5"
              indeterminate
              :color="dark ? 'white' : 'primary'"
              size="80"
            ></v-progress-circular>
          </v-container>
        </v-main>
      </v-app>
    </transition>
  </div>
</template>

<script>
import { loadLanguageAsync } from "@/plugins/i18n.js";
import { mapState } from "vuex";

export default {
  name: "App",

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
  },

  async created() {
    try {
      const locale = this.$ls.get(
        "locale",
        window.navigator.language.substr(0, 2)
      );

      await loadLanguageAsync(locale);
      await this.$store.dispatch("Preferencias/setTheme");

      const template = this.$ls.get("template", "light");

      if (template) {
        if (template === "light") {
          this.$vuetify.theme.dark = false;
          this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
        } else {
          this.$vuetify.theme.dark = true;
          this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
        }
      } else {
        this.$ls.set("template", "light");
      }

      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
// Reversed input variant
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

// Bonus "expand" variant
.v-input--expand .v-input__slot {
  // justify-content: space-between;
  .v-label {
    display: block;
    flex: 1;
  }
}

.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba(0, 0, 0, 0.12) !important;
}

// .v-text-field.v-text-field--enclosed .v-text-field__details {
//   margin-bottom: 0px !important;
// }

.data-tables__row-click {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.data__row-click {
  cursor: pointer;
}

.v-data-table {
  th {
    font-size: 0.88rem !important;
    height: 32px !important;
    text-transform: uppercase;
  }
  td {
    font-size: 0.76rem !important;
    height: 32px !important;
  }
  .v-data-table__mobile-table-row {
    display: flex !important;
    max-width: 100%;
    flex-wrap: wrap;
    margin-bottom: 15px !important;
    border: thin solid rgba(0, 0, 0, 0.12) !important;
    padding: 10px 0;
    border-radius: 4px;
  }

  .v-data-table__mobile-row {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 0px !important;
    margin-bottom: 10px;

    .v-data-table__mobile-row__header {
      display: none;
    }

    .v-data-table__mobile-row__cell {
      font-size: 12px;
      width: 100%;
      text-align: left;
    }

    &:last-child {
      border-bottom: none !important;
      margin-bottom: 0px;
    }
  }
}

.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item {
  padding-left: 30px !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 8px !important;
}

// ::-webkit-scrollbar {
//   height: 8px;
//   width: 4px;
//   background: #eeeeee;
//   border: 0px !important;
//   border-radius: 4px;
// }
// ::-webkit-scrollbar-thumb:horizontal {
//   background: gray;
//   border: 2px solid #eeeeee !important;
//   border-radius: 10px;
// }
</style>
