const routes = [
  /*CALENDARIO MIDIAS SOCIAIS*/
  {
    path: "/cliente/midias-sociais/calendario-midias-sociais",
    component: () =>
      import(
        "@/views/cliente/midias_sociais/calendario_midias_sociais/ListaCalendarioMidiaSociais.vue"
      ),
    meta: {
      menu: "dashboard",
      title: "Calendário-Mídias-Sociais",
    },
  },
  {
    path: "/cliente/midias-sociais/calendario-midias-sociais/:calendarioId",
    component: () =>
      import(
        "@/views/cliente/midias_sociais/calendario_midias_sociais/calendario_postagens/ListaCalendarioMidiaSociais.vue"
      ),
    meta: {
      menu: "dashboard",
      title: "Postagem-Calendario",
    },
  },
  /*------------------------------------------------------------------------------------------------*/
];
export default routes;
