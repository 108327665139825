import store from "@/store";

import Vue from "vue";
import VueRouter from "vue-router";
import admin_routes from "./routes/admin";
import cliente_routes from "./routes/cliente";
import colaborador_routes from "./routes/colaborador";
import gestor_routes from "./routes/gestor";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () => import("@/views/PaginaNaoEncontrada.vue"),
    meta: {
      title: "Pagina não Encontrada",
    },
  },
  {
    path: "/",
    meta: {
      login: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/admin",
    redirect: "/admin/home",
    component: () => import("@/layouts/admin/AdminLayout.vue"),
    meta: {
      login: true,
      perfil: "ADMIN",
      title: "ADMIN",
    },
    children: [...admin_routes],
  },
  {
    path: "/gestor",
    redirect: "/gestor/dashboard",
    component: () => import("@/layouts/gestor/GestorLayout.vue"),
    meta: {
      login: true,
      perfil: "GESTOR",
      title: "GESTOR",
    },
    children: [...gestor_routes],
  },
  {
    path: "/cliente",
    redirect: "/cliente/home",
    component: () => import("@/layouts/cliente/ClienteLayout.vue"),
    meta: {
      login: true,
      perfil: "CLIENTE",
      title: "CLIENTE",
    },
    children: [...cliente_routes],
  },
  {
    path: "/colaborador",
    redirect: "/colaborador/home",
    component: () => import("@/layouts/colaborador/ColaboradorLayout.vue"),
    meta: {
      login: true,
      perfil: "COLABORADOR",
      title: "COLABORADOR",
    },
    children: [...colaborador_routes],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = `${to.meta.title} - TOCS` || "TOCS";

  const token = Vue.ls.get("token");


  if (token) {
    try {
      await store.dispatch("Usuario/getUsuario");

      const isGestor = store.getters["Usuario/isGestor"];
      if (isGestor) {
        await store.dispatch("Componentes/getComponentes");
      }
      await store.dispatch("Preferencias/getPreferencias");
    } catch (error) {
      if (to.fullPath != "/login") {
        next("/login");
      }
    }
  } else {
  
    if (to.fullPath != "/login") {
      next("/login");
    }
  }

  if (to.matched.some((record) => record.meta.login) && token) {
    const perfisToArray = store.getters["Usuario/perfisToArray"];
    const isAdmin = store.getters["Usuario/isAdmin"];
    const isGestor = store.getters["Usuario/isGestor"];
    const isCliente = store.getters["Usuario/isCliente"];
    const isColaborador = store.getters["Usuario/isColaborador"];

    let endRoute = "";
    if (isAdmin) {
      endRoute = "/admin";
    } else if (isGestor) {
      endRoute = "/gestor";
    } else if (isCliente) {
      endRoute = "/cliente";
    } else if (isColaborador) {
      endRoute = "/colaborador";
    }

    if (to.matched[0].meta.perfil) {
      if (!perfisToArray.length) {
        Vue.prototype.$toast.error("Acesso não autorizado!");
        store.dispatch("Usuario/deslogarUsuario");
        next("/login");
      } else {
        if (!perfisToArray.includes(to.matched[0].meta.perfil) && !isAdmin) {

          Vue.prototype.$toast.error("Acesso não autorizado!");
          next(endRoute);

        } else {
          if (to.meta.componente && to.meta.type && isGestor) {
            const permissao = await store.dispatch("Componentes/getAccess", {
              componente: to.meta.componente,
              type: to.meta.type,
            });

            if (permissao) {
              next();
            } else {
              Vue.prototype.$toast.error("Acesso não autorizado!");
            }
          } else {
            next();
          }
        }
      }
    } else {
      if (to.fullPath == "/") {
      
        next(endRoute);
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (to.name != "login") {
    Vue.ls.set("current_route", to.path);
  }
  Vue.ls.set("last_route", from.path);
});

export default router;
