import { getQtdNaoLidas } from "@/api/gestor/solicitacoes_servicos.js";

const initialState = () => ({
  qtde_msgs_solicitacoes: null,
});

const state = initialState();

const getters = {};

const actions = {
  countSolicitacoesServicos({ commit }) {
    return getQtdNaoLidas().then((response) => {
      let qtde = null;
      qtde = response;

      commit("UPDATE_COUNT_SOLICITACOES_SERVICOS", qtde);
    });
  },
};

const mutations = {
  UPDATE_COUNT_SOLICITACOES_SERVICOS(state, payload) {
    state.qtde_msgs_solicitacoes = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
