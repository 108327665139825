import api from "../axios_service.js";

export async function getUsuarios(filtros) {
  const response = await api.get(`/admin/usuarios${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function getUsuario(id) {
  const response = await api.get(`/admin/usuarios/${id}`);
  return response.data.result;
}

export function putUsuario(id, usuario) {
  let body = {};
  for (let key in usuario) {
    body[key] = usuario[key];
  }
  return api.put(`/admin/usuarios/${id}`, body);
}

export function postUsuario(usuario) {
  let body = {};
  for (let key in usuario) {
    body[key] = usuario[key];
  }
  return api.post("/admin/usuarios/add", body);
}
