const routes = [
  /*CRONOGRAMA DAS MIDIAS SOCIAIS */
  {
    path:
      "/gestor/formulario-trabalho/midias-sociais/cronograma-midias-sociais",
    component: () =>
      import(
        "@/views/gestor/formularios/midias_sociais/cronograma_midias_sociais/CronogramaMidiasSociais.vue"
      ),
    meta: {
      menu: "dashboard",
      title: "Cronograma-Mídias-Sociais",
    },
  },
  /*------------------------------------------------------------------------------------*/
  /*CALENDARIO MIDIAS SOCIAIS*/
  {
    path:
      "/gestor/formulario-trabalho/midias-sociais/calendario-midias-sociais",
    component: () =>
      import(
        "@/views/gestor/formularios/midias_sociais/calendario_midias_sociais/CalendariosMidiaSociais.vue"
      ),
    meta: {
      menu: "dashboard",
      title: "Calendário-Mídias-Sociais",
      componente: "vueGestorFormulariosTrabalhoMidiasSociais",
      type: "menu",
    },
  },
  {
    path:
      "/gestor/formulario-trabalho/midias-sociais/calendario-midias-sociais/:calendarioId",
    component: () =>
      import(
        "@/views/gestor/formularios/midias_sociais/calendario_midias_sociais/calendario_postagens/CalendarioMidiaSociais.vue"
      ),
    meta: {
      menu: "dashboard",
      title: "Postagem-Calendario",
    },
  },
  /*------------------------------------------------------------------------------------------------*/
];
export default routes;
