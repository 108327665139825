import api from "../axios_service.js";

export async function check(colaborador_id) {
  const response = await api.get(
    `/colaborador/stopwatch-check/${colaborador_id}`
  );
  return response.data.result;
}

export async function historico(atividade_id) {
  const response = await api.get(
    `/colaborador/stopwatch-historico/${atividade_id}`
  );
  return response.data.result;
}

export function start(body) {
  return api.post("/colaborador/stopwatch-start", body);
}

export function stop(body) {
  return api.post("/colaborador/stopwatch-stop", body);
}

export function putNote(id, note) {
  let body = {};
  for (let key in note) {
    body[key] = note[key];
  }
  return api.put(`/colaborador/stopwatch-note/${id}`, body);
}
