const routes = [
  {
    path: "clientes",
    component: () => import("@/views/colaborador/clientes/Clientes.vue"),
    meta: {
      menu: "dashboard",
      title: "Clientes",     
    },
  },
];

export default routes;
