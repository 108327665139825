import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    locale: "",
    menuDinamico: "",
    template: {
      dark: false,
      light: true,
    },
    bitWiseTable: {
      visualizar: 1,
      adicionar: 2,
      editar: 4,
      deletar: 8,
      imprimir: 16,
      exportar: 32,
      limitar: 64,
      menu: 128,
    },
  },
  actions: {},
  mutations: {
    UPDATE_TEMPLATE(state, payload) {
      state.template = payload;
    },
    UPDATE_MENUDINAMICO(state, payload) {
      state.menuDinamico = payload;
    },
    UPDATE_LOCALE(state, payload) {
      state.locale = payload;
    },
  },
});
